<template>
  <div ref="calendar" class="m-calendar">
    <div class="m-toolbar">
      <!-- <div class="m-year-selector">
        <a class="m-prev-btn" @click="changeYear('prev')"></a>
        <span>{{showDate.year}} {{yearName}}</span>
        <a class="m-next-btn" @click="changeYear('next')"></a>
      </div> -->
      <span
        :class="{ active: !hide }"
        class="show-calender"
        @click="hide = !hide"
        ><i class="iconfont iconfont-zhankai2"></i
      ></span>
      <div class="m-month-selector">
        <van-icon name="arrow-left" @click="changeMonth('prev')" />
        <span>{{ showDate.year }}{{ yearName }}</span>
        <span>{{ monthNames[showDate.month - 1] }}</span>
        <van-icon name="arrow" @click="changeMonth('next')" />
      </div>
      <p>
        <span class="today" @click="changeDateView()">今天</span>
        <span class="clear" @click="changeDateView('')">清空</span>
      </p>
    </div>
    <div class="m-week-header">
      <div v-for="item in weekNames" :key="item" class="m-week-day">
        {{ item }}
      </div>
    </div>
    <div
      :class="['m-months-container', { short: hide }]"
      @touchstart="touchstart"
      @touchmove="touchmove"
      @touchend="touchend"
    >
      <div
        class="m-months-wrapper"
        :style="{ transform: `translate3d(${-translateX * 100}%, 0, 0)` }"
      >
        <div
          v-for="(month, monthIndex) in fullDate"
          :key="monthIndex"
          class="m-months"
          :style="{
            transform: `translate3d(${(monthIndex -
              1 +
              translateX +
              (isTouching ? touch.x : 0)) *
              100}%, 0, 0)`,
            transitionDuration: isTouching ? '0s' : '.3s'
          }"
        >
          <div
            v-for="(week, weekIndex) in month"
            :key="weekIndex"
            class="m-row"
          >
            <div
              v-for="(day, dayIndex) in week"
              :key="dayIndex"
              class="m-day"
              @click="onDayClick(day)"
            >
              <span
                :class="{
                  'm-day-num': true,
                  hasdata: day.hasData,
                  'm-grey': day.isGrey,
                  'm-today': day.isToday,
                  'm-disable': day.isDisable,
                  'm-select': day.isSelect,
                  'm-during': day.isDuring
                }"
              >
                {{ day.value }}
              </span>
              <slot name="day" :date="day" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import './inlineCalendar.less';
import dayjs from "dayjs";
import { getEquipmentHasData } from "@/api/basics/equipment";

let touchStartPosition;
let touchEndPosition;
let timeStamp;
export default {
  name: "InlineCalendar",
  props: {
    defaultDate: {
      type: [Date, Number, Array, String, dayjs]
    },
    disabledDate: {
      type: Array,
      default() {
        return [];
      }
    },
    minDate: {
      type: [Date, Number, Array, String, dayjs]
    },
    maxDate: {
      type: [Date, Number, Array, String, dayjs]
    },
    mode: {
      type: String,
      default: "during"
    },
    dayClick: {
      type: Function,
      default() {
        return function() {
          return true;
        };
      }
    },
    enableTouch: {
      type: Boolean,
      default: true
    },
    monthNames: {
      type: Array,
      default() {
        return [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月"
        ];
      }
    },
    weekNames: {
      type: Array,
      default() {
        return ["一", "二", "三", "四", "五", "六", "日"];
      }
    },
    yearName: {
      type: String,
      default: "年"
    },
    restrictCurrentMonth: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    mode() {
      this.init();
    }
  },
  data() {
    return {
      fullDate: [[], [], []],
      translateX: 0,
      showDate: {
        year: undefined,
        month: undefined
      },
      dateNow: {
        year: dayjs().year(),
        month: dayjs().month() + 1,
        date: dayjs().date()
      },
      selectDate: [],
      touch: {
        x: 0,
        y: 0
      },
      isTouching: false,
      hide: true,
      currentHasData: [],
      currentTabName: "a"
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(date) {
      this.selectDate = [];
      let { defaultDate, mode } = this;
      if (date) {
        defaultDate = date;
      }
      let dateToShow = dayjs().startOf("month");
      if (mode === "single" && defaultDate) {
        this.selectDate = dayjs(defaultDate).startOf("day");
        dateToShow = this.selectDate.startOf("month");
      }
      if (mode === "multiple" && Array.isArray(defaultDate)) {
        if (defaultDate.length > 0) {
          this.selectDate = defaultDate.map(item => dayjs(item).startOf("day"));
        }
      }
      if (mode === "during" && Array.isArray(defaultDate)) {
        if (defaultDate.length === 2) {
          const startDate = dayjs(defaultDate[0]).startOf("day");
          const endDate = dayjs(defaultDate[1]).startOf("day");
          if (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
            this.selectDate = [startDate, endDate];
          }
        }
      }
      this.showDate = {
        year: dateToShow.year(),
        month: dateToShow.month() + 1
      };
      this.getFullDate("switch");
    },
    // 获取该天下面是否有检修检测、润滑保养、检定记录的数据
    async getEquipmentHasData(tabName) {
      let year = this.showDate.year;
      let month = this.showDate.month;
      let obj = {
        a: 1,
        b: 2,
        d: 3
      };
      tabName ? (this.currentTabName = tabName) : null;
      const m = `00${month}`.slice(-2);
      const firstDay = `${year}-${m}-01`;
      const monthDays = this.getMonthDays(year, month);
      const lastDay = `${year}-${m}-${monthDays}`;
      // 重新计算days
      this.currentHasData = this.fullDate[1]
        .flat()
        .sort((a, b) => a.value - b.value)
        .filter(i => !i.isGrey);
      if (obj[this.currentTabName]) {
        try {
          await getEquipmentHasData(this.$route.params.id, {
            startDate: firstDay,
            endDate: lastDay,
            recordType: obj[this.currentTabName]
          }).then(res => {
            Object.values(res).forEach((i, index) => {
              this.currentHasData[index].hasData = i;
            });
            this.createDayHasData();
          });
        } catch (error) {
          this.currentHasData.forEach(i => (i.hasData = false));
          this.createDayHasData();
        }
      } else {
        this.currentHasData.forEach(i => (i.hasData = false));
        this.createDayHasData();
      }
    },
    // 构造当前月的某天是否有数据
    createDayHasData() {
      this.fullDate[1].map(i => {
        i.forEach(j => {
          this.currentHasData.forEach(k => {
            if (j.value === k.value) {
              this.$set(j, "hasData", k.hasData);
            }
          });
        });
      });
      this.$forceUpdate();
    },
    touchstart(event) {
      if (this.enableTouch) {
        touchStartPosition = event.touches[0].clientX;
        touchEndPosition = event.touches[0].clientY;
        timeStamp = event.timeStamp;
        this.touch = {
          x: 0,
          y: 0
        };
        this.isTouching = true;
      }
    },
    touchmove(event) {
      if (this.enableTouch) {
        this.touch = {
          x:
            (event.touches[0].clientX - touchStartPosition) /
            this.$refs.calendar.offsetWidth,
          y:
            (event.touches[0].clientY - touchEndPosition) /
            this.$refs.calendar.offsetHeight
        };
      }
    },
    touchend(event) {
      if (this.enableTouch) {
        this.isTouching = false;
        const during = dayjs(event.timeStamp).diff(timeStamp);
        if (
          Math.abs(this.touch.x) > Math.abs(this.touch.y) &&
          Math.abs(this.touch.x * this.$refs.calendar.offsetWidth) > 20
        ) {
          if (this.touch.x > 0) {
            this.changeMonth("prev");
          } else if (this.touch.x < 0) {
            this.changeMonth("next");
          }
        } else {
          this.touch = {
            x: 0,
            y: 0
          };
        }
      }
    },
    // 触发change事件
    emitChange() {
      let arr = [];
      this.selectDate.forEach((i, index) => {
        let str = "";
        let month = i.$M < 9 ? `${"0" + (i.$M + 1)}` : `${i.$M + 1}`;
        let day = i.$D < 10 ? `${"0" + i.$D}` : `${i.$D}`;
        str += `${i.$y}-${month}-${day}`;
        arr.push(str);
      });
      this.$emit("change", arr);
    },
    // 触发切换年月事件
    emitSwitch(showDate) {
      if (this.restrictCurrentMonth) {
        this.selectDate = [];
      }
      this.$emit("switch", showDate);
    },
    // 日期点击事件
    onDayClick(day) {
      if (!this.dayClick(day.dateTime)) {
        return;
      }
      switch (this.$props.mode) {
        case "single":
          if (!day.isSelect && !day.isDisable) {
            this.selectDate = day.dateTime;
            this.getFullDate();
            this.emitChange();
          }
          break;
        case "multiple":
          if (!day.isSelect && !day.isDisable) {
            this.selectDate.push(day.dateTime);
            this.getFullDate();
            this.emitChange();
          } else {
            if (this.selectDate.length > 1) {
              this.selectDate = this.selectDate.filter(
                item => !item.isSame(day.dateTime)
              );
              this.getFullDate();
              this.emitChange();
            }
          }
          break;
        case "during":
          if (day.isDisable) return;
          if (day.isGrey) return;
          if (this.restrictCurrentMonth && day.isGrey) return;
          if (this.selectDate.length === 0) {
            this.selectDate = [day.dateTime];
          } else if (this.selectDate.length === 1) {
            this.selectDate.push(day.dateTime);
            if (this.selectDate[1].isBefore(this.selectDate[0])) {
              this.selectDate.reverse();
            }
          } else if (this.selectDate.length === 2) {
            this.selectDate = [day.dateTime];
          }
          this.getFullDate();
          this.emitChange();
          break;
      }
    },
    // 切换年份
    changeYear(action) {
      const date = dayjs(`${this.showDate.year}-${this.showDate.month}`);
      let computedDate;
      switch (action) {
        case "prev":
          this.translateX += 1;
          computedDate = date.subtract(1, "year");
          break;
        case "next":
          this.translateX -= 1;
          computedDate = date.add(1, "year");
          break;
      }
      this.showDate = {
        year: computedDate.year(),
        month: computedDate.month() + 1
      };
      this.emitSwitch(this.showDate);
      this.getFullDate();
    },
    // 切换月份
    changeMonth(action) {
      const date = dayjs(`${this.showDate.year}-${this.showDate.month}`);
      let computedDate;
      switch (action) {
        case "prev":
          this.translateX += 1;
          computedDate = date.subtract(1, "month");
          break;
        case "next":
          this.translateX -= 1;
          computedDate = date.add(1, "month");
          break;
      }
      this.showDate = {
        year: computedDate.year(),
        month: computedDate.month() + 1
      };
      this.emitSwitch(this.showDate);
      this.getFullDate("switch");
    },
    // 暴露出去的方法：切换已选的时间
    changeDate(date) {
      if (dayjs(date).isValid() || Array.isArray(date)) {
        this.init(date);
      } else {
        console.error("Type of parameter is invalid!");
      }
    },
    // 点击今天，快捷跳转到今天
    changeDateView(date = dayjs()) {
      const changeDate = dayjs(date || dayjs());
      this.showDate = {
        year: changeDate.year(),
        month: changeDate.month() + 1
      };
      this.selectDate = [];
      this.getFullDate();
      if (date) {
        // 今天
        this.onDayClick(this.fullDate[1].flat().find(i => i.isToday));
      } else {
        // 清空
        this.getEquipmentHasData();
        this.emitChange();
      }
    },
    getFullDate(from) {
      const date = dayjs(`${this.showDate.year}-${this.showDate.month}`);
      const thisDate = this.getDate(date);
      const prevDate = this.getDate(date.subtract(1, "month"));
      const nextDate = this.getDate(date.add(1, "month"));
      this.fullDate = [prevDate.fullDate, thisDate.fullDate, nextDate.fullDate];
      // 进入页面获取当前月的检验记录、润滑保养记录、检定记录是否有数据
      from === "switch" ? this.getEquipmentHasData() : this.createDayHasData();
    },
    // 当前日期是否被选中
    isSelect(date) {
      let select = false;
      switch (this.$props.mode) {
        case "single":
          if (this.selectDate && date.isSame(this.selectDate)) {
            select = true;
          }
          break;
        case "multiple":
          if (
            this.selectDate.length > 0 &&
            this.selectDate.some(item => date.isSame(item))
          ) {
            select = true;
          }
          break;
        case "during":
          if (
            this.selectDate.length > 0 &&
            this.selectDate.some(item => date.isSame(item))
          ) {
            select = true;
          }
          break;
      }
      return select;
    },
    // 当前时间是否在selectDate之间
    isBetting(date) {
      if (this.mode === "during") {
        const startDate = this.selectDate[0];
        const endDate = this.selectDate[1];
        if (this.selectDate.length === 1) {
          return date.isSame(startDate);
        } else if (this.selectDate.length === 2) {
          return (
            (date.isAfter(startDate) && date.isBefore(endDate)) ||
            date.isSame(startDate) ||
            date.isSame(endDate)
          );
        }
      }
      return false;
    },
    getIsDisable(dateTime) {
      let isDisable = false;
      const disabledDate = this.disabledDate.map(item =>
        dayjs(item).startOf("day")
      );
      if (this.minDate || this.maxDate) {
        if (this.minDate) {
          const minDate = dayjs(this.minDate).startOf("day");
          isDisable = dateTime.isBefore(minDate);
        }
        if (!isDisable && this.maxDate) {
          const maxDate = dayjs(this.maxDate).endOf("day");
          isDisable = dateTime.isAfter(maxDate);
        }
      } else if (disabledDate.length > 0) {
        if (this.mode !== "during") {
          isDisable = disabledDate.some(item => item.isSame(dateTime));
        }
      }
      return isDisable;
    },
    getDate(thisDate) {
      let date = [];
      const prevDate = thisDate.subtract(1, "month");
      const nextDate = thisDate.add(1, "month");
      const firstDayOfWeek = thisDate.day() || 7;
      const dayCountOfThisMonth = thisDate.daysInMonth();
      const dayCountOfPrevMonth = prevDate.daysInMonth();
      const prevIndexOfThisMonth = firstDayOfWeek - 1;
      const NextIndexOfThisMonth = firstDayOfWeek + dayCountOfThisMonth - 2;
      const disabledDate = this.disabledDate.map(item =>
        dayjs(item).startOf("day")
      );
      for (let i = 0; i < 7 * 6; i++) {
        // 上月
        if (i < prevIndexOfThisMonth) {
          const value = dayCountOfPrevMonth - (firstDayOfWeek - i - 2);
          const dateTime = prevDate.date(value);
          date[i] = {
            value,
            dateTime,
            isGrey: true,
            isToday: dateTime.isSame(dayjs().startOf("day")),
            isSelect: this.isSelect(dateTime),
            isDisable: this.getIsDisable(dateTime),
            isDuring: this.isBetting(dateTime)
          };
        }
        // 当月
        if (i >= prevIndexOfThisMonth && i <= NextIndexOfThisMonth) {
          const value = i - firstDayOfWeek + 2;
          const dateTime = thisDate.date(value);
          date[i] = {
            value,
            dateTime,
            isGrey: false,
            isToday: dateTime.isSame(dayjs().startOf("day")),
            isSelect: this.isSelect(dateTime),
            isDisable: this.getIsDisable(dateTime),
            isDuring: this.isBetting(dateTime)
          };
        }
        // 下月
        if (i > NextIndexOfThisMonth) {
          const value = i - firstDayOfWeek - dayCountOfThisMonth + 2;
          const dateTime = nextDate.date(value);
          date[i] = {
            value,
            dateTime,
            isGrey: true,
            isToday: dateTime.isSame(dayjs().startOf("day")),
            isSelect: this.isSelect(dateTime),
            isDisable: this.getIsDisable(dateTime),
            isDuring: this.isBetting(dateTime)
          };
        }
      }
      const fullDate = [];
      for (let i = 0; i < 6; i++) {
        fullDate.push(date.slice(i * 7, (i + 1) * 7));
      }
      return {
        fullDate
      };
    },
    // 获取某个月的天数
    getMonthDays(year, month) {
      const d = new Date(year, month, 0);
      return d.getDate();
    }
  }
};
</script>

<style lang="scss" scoped>
.m-calendar {
  background: #fff;
  .m-toolbar {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    padding: 0 10px;
    .show-calender {
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      display: inline-block;
      border-radius: 2px;
      i {
        position: relative;
        font-size: 14px;
      }
      &.active {
        background-color: #1676ff;
        i {
          color: #fff;
        }
      }
    }
    .m-month-selector {
      vertical-align: top;
      i {
        margin: 0 10px;
      }
    }
    .today,
    .clear {
      color: #1676ff;
      font-size: 10px;
      display: block;
    }
    .today {
      margin-bottom: 5px;
    }
  }
  .m-week-header {
    position: relative;
    display: flex;
    box-sizing: border-box;
    font-size: 12px;
    color: #aaaaaa;
    border-bottom: 1px solid #e4e7ed;
    padding: 10px 0;
    .m-week-day {
      flex: 1;
      text-align: center;
      line-height: 18px;
    }
  }
  .m-months-container {
    position: relative;
    box-sizing: border-box;
    height: 200px;
    overflow: hidden;
    .m-months-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .m-months {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        will-change: transform;
        .m-row {
          position: relative;
          display: flex;
          height: 40px;
          &::before {
            content: "";
          }
          .m-day {
            position: relative;
            line-height: 40px;
            font-size: 12px;
            width: calc(100% / 7);
            text-align: center;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            .m-day-num {
              width: 35px;
              height: 35px;
              line-height: 35px;
              display: inline-block;
              border-radius: 100%;
            }
            .hasdata {
              position: relative;
              &::before {
                position: absolute;
                content: "";
                bottom: 5px;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background-color: #ee3424;
              }
            }
            .m-grey {
              color: #b8b8b8;
              display: none;
            }
            .m-today {
              color: #fff;
              background: #1676ff;
            }
            .m-disable {
              color: #b8b8b8;
              text-decoration: line-through;
            }
            .m-select {
              background: #007aff !important;
              color: #fff !important;
            }
            .m-during {
              background: #d0e3ff;
              color: #646b74;
              width: 100%;
              height: 100%;
              line-height: 40px;
              border-radius: initial;
            }
          }
        }
      }
    }
  }
  .short {
    height: 25px !important;
  }
}
</style>
