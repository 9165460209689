<template>
  <div class="equipment-wrapper">
    <van-nav-bar
      title="设备信息一览"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-info">
      <span class="report-fault" @click="onAddFault()">报故障</span>
      <div class="equipment-desc">
        <div
          class="equipment-desc-box"
          :class="{
            key: info.level == 2,
            core: info.level == 3,
            scrap: info.scrap
          }"
        >
          <span v-if="info.scrap" class="scrap-mark">报废</span>
          <span class="equipment-type">{{ info.typeName }}</span>
          <div class="equipment-name">
            {{ info.name }}
            <span class="equipment-no">{{ result }}</span>
          </div>
          <div class="equipment-category">{{ info.levelName }}</div>
          <div class="equipment-status">
            <span :class="[`mark${info.equipmentStatus}`, 'mark']"></span>
            <span>{{ info.equipmentStatusName }}</span>
          </div>
          <span class="equipment-detail-btn" @click="onToInfo()"
            >查看详情<i class="iconfont iconfont-jiantou"></i
          ></span>
        </div>
      </div>
      <div class="equipment-modules">
        <span
          v-for="item in modulesCom"
          :key="item.name"
          class="equipment-module"
          @click="onModuleClick(item)"
        >
          <span class="equipment-module-icon">
            <svg class="icon svg-icon" aria-hidden="true">
              <use :xlink:href="item.icon"></use>
            </svg>
          </span>
          <span class="equipment-module-name">{{ item.name }}</span>
        </span>
      </div>
      <div class="equipment-calender">
        <inlineCalendar
          ref="calendar"
          :tab-name="activeName"
          @change="onCalenderChange"
        />
      </div>
      <div class="equipment-tabs">
        <van-tabs v-model="activeName" @click="onTabChange">
          <van-tab title="检验检测记录" name="a">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="tabDataObj.a.length ? '没有更多了' : ''"
              error-text="加载失败，请稍后再试！"
              @load="getData(activeName)"
            >
              <van-empty
                v-if="tabDataObj.a.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <p v-else class="total">共{{ total }}条结果</p>
              <div
                v-for="item in tabDataObj.a"
                :key="item.id"
                class="equipment-item"
              >
                <div class="item-project">
                  <div class="left">
                    <span class="name">{{ item.schemeName }}</span>
                    <span class="type">{{ item.cycleTypeName }}</span>
                    <span class="scheme-type">{{ item.schemeTypeName }}</span>
                    <div class="info">
                      <span>{{ item.schemeCode }}</span>
                      <span>{{ item.planDate }}</span>
                    </div>
                  </div>
                  <div>
                    <img
                      width="44px"
                      height="37px"
                      :src="
                        require(`@/assets/images/equipment/recordStatus${item.status}.png`)
                      "
                    />
                  </div>
                </div>
                <van-divider />
              </div>
            </van-list>
          </van-tab>
          <van-tab title="润滑保养记录" name="b">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="tabDataObj.b.length ? '没有更多了' : ''"
              error-text="加载失败，请稍后再试！"
              @load="getData(activeName)"
            >
              <div class="equipment-list">
                <van-empty
                  v-if="tabDataObj.b.length == 0"
                  :image="emptyImage"
                  description="暂无数据"
                />
                <p v-else class="total">共{{ total }}条结果</p>
                <div
                  v-for="item in tabDataObj.b"
                  :key="item.id"
                  class="equipment-item"
                >
                  <div class="item-project">
                    <div class="left">
                      <span class="name">{{ item.equipParasName }}</span>
                      <span class="type">{{ item.planTypeName }}</span>
                      <div class="info">
                        <span>{{ item.equipParasId }}</span>
                        <span>{{ item.endDay }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        width="44px"
                        height="37px"
                        :src="
                          require(`@/assets/images/equipment/recordStatus${item.status}.png`)
                        "
                      />
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </van-list>
          </van-tab>
          <van-tab title="检定记录" name="d">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="tabDataObj.d.length ? '没有更多了' : ''"
              error-text="加载失败，请稍后再试！"
              @load="getData(activeName)"
            >
              <div class="equipment-list">
                <van-empty
                  v-if="tabDataObj.d.length == 0"
                  :image="emptyImage"
                  description="暂无数据"
                />
                <p v-else class="total">共{{ total }}条结果</p>
                <div
                  v-for="item in tabDataObj.d"
                  :key="item.id"
                  class="equipment-item"
                >
                  <div class="item-project">
                    <div class="left">
                      <span class="name">{{ item.schemeName }}</span>
                      <span class="type">{{ item.planTypeName }}</span>
                      <div class="info">
                        <span>{{ item.schemeCode }}</span>
                        <span>{{ item.planDate }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        width="44px"
                        height="37px"
                        :src="
                          require(`@/assets/images/equipment/recordStatus${item.status}.png`)
                        "
                      />
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </van-list>
          </van-tab>
          <van-tab title="巡检记录" name="c">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="tabDataObj.c.length ? '没有更多了' : ''"
              error-text="加载失败，请稍后再试！"
              @load="getData(activeName)"
            >
              <div class="equipment-list">
                <van-empty
                  v-if="tabDataObj.c.length == 0"
                  :image="emptyImage"
                  description="暂无数据"
                />
                <p v-else class="total">共{{ total }}条结果</p>
                <div
                  v-for="item in tabDataObj.c"
                  :key="item.id"
                  class="equipment-item"
                >
                  <div class="item-project">
                    <div class="left">
                      <span class="name">{{ item.name }}</span>
                      <div class="info">
                        <van-icon
                          name="xunjian"
                          class-prefix="iconfont"
                          class="iconfont-xunjian"
                          size="16"
                        />
                        <span>{{
                          item.taskTypeName === "点巡检"
                            ? "设备巡检"
                            : item.taskTypeName
                        }}</span>
                        <van-icon
                          name="riqi"
                          class-prefix="iconfont"
                          class="iconfont-riqi"
                          size="15"
                        />
                        <span>
                          {{
                            `${item.startTime || "--:--:--"} - ${item.endTime ||
                              "--:--:--"}`
                          }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <van-icon
                        :name="statusIconSwitch(item.status)"
                        class-prefix="iconfont"
                        :class="`iconfont-${statusIconSwitch(item.status)}`"
                        size="44"
                      />
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </van-list>
          </van-tab>
          <van-tab title="点检记录" name="f">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="tabDataObj.f.length ? '没有更多了' : ''"
              error-text="加载失败，请稍后再试！"
              @load="getData(activeName)"
            >
              <div class="equipment-list">
                <van-empty
                  v-if="tabDataObj.f.length == 0"
                  :image="emptyImage"
                  description="暂无数据"
                />
                <p v-else class="total">共{{ total }}条结果</p>
                <div
                  v-for="item in tabDataObj.f"
                  :key="item.id"
                  class="equipment-item"
                >
                  <div class="item-project">
                    <div class="left">
                      <span class="name">{{ item.name }}</span>
                      <div class="info">
                        <van-icon
                          name="xunjian"
                          class-prefix="iconfont"
                          class="iconfont-xunjian"
                          size="16"
                        />
                        <span class="equipment-name">点巡检</span>
                        <van-icon
                          name="riqi"
                          class-prefix="iconfont"
                          class="iconfont-riqi"
                          size="15"
                        />
                        <span class="equipment-name">{{
                          `${item.startTime || "--:--:--"} - ${item.endTime ||
                            "--:--:--"}`
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <van-icon
                        :name="statusIconSwitch(item.status)"
                        class-prefix="iconfont"
                        :class="`iconfont-${statusIconSwitch(item.status)}`"
                        size="44"
                      />
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </van-list>
          </van-tab>
          <van-tab name="h">
            <!-- 保持滚动 -->
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEquipmentInfo,
  getEquipmentOverhaulRecord,
  getEquipmentMaintainverhaulRecord,
  getEquipmentPatrolRecord,
  getEquipmentVerifyRecord,
  getPointCheckRecord
} from "@/api/basics/equipmentRx";
import inlineCalendar from "./inlineCalendar.vue";
import equipmentMixin from "./equipmentMixin";

export default {
  mixins: [equipmentMixin],
  components: {
    inlineCalendar
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: "a",
      info: {},
      selectDate: [],
      modules: [
        {
          icon: "#iconfont-jianxiujihua",
          name: "技术参数",
          routerName: "equipmentTechParams"
        },
        {
          icon: "#iconfont-jianxiujihua",
          name: "设备文档",
          routerName: "equipmentDoc"
        },
        {
          icon: "#iconfont-anquanfujian",
          name: "安全附件",
          routerName: "equipmentSafetyAffix"
        },
        {
          icon: "#iconfont-guanlianfujian1",
          name: "关联辅件",
          routerName: "equipmentLinkAccessory"
        },
        {
          icon: "#iconfont-jianxiujihua",
          name: "检验周期",
          routerName: "equipmentOverhaulCycle"
        },
        {
          icon: "#iconfont-baoyangjihua1",
          name: "润滑保养周期",
          routerName: "equipmentMaintainPlan"
        },
        {
          icon: "#iconfont-jiandingjihua1",
          name: "检定周期",
          routerName: "equipmentVerificationPlan"
        },
        {
          icon: "#iconfont-jiandingjihua1",
          name: "巡检检测周期",
          routerName: "equipmentInspectionPlan"
        },
        {
          icon: "#iconfont-guzhangjilu",
          name: "故障记录",
          routerName: "DeviceFault"
        },
        {
          icon: "#iconfont-gongdanguanli",
          name: "维修工单",
          routerName: "workOrderRxList"
        },
        {
          icon: "#iconfont-shebeiwendang",
          name: "备品备件",
          routerName: "equipmentParts"
        }
      ],
      tabDataObj: {
        a: [],
        b: [],
        c: [],
        d: [],
        f: []
      },
      loading: false,
      finished: false,
      total: 0,
      page: 1
    };
  },
  computed: {
    modulesCom() {
      return this.modules.filter(a => !a.hide);
    },
    result() {
      if (this.info.machineCode && !this.info.machineNo) {
        return this.info.machineCode;
      }
      if (!this.info.machineCode && this.info.machineNo) {
        return this.info.machineNo;
      }
      if (this.info.machineCode && this.info.machineNo) {
        return `${this.info.machineCode}/${this.info.machineNo}`;
      }
      return "";
    },
    statusIconSwitch() {
      return status => {
        const obj = {
          0: "weikaishi",
          1: "jinhangzhong",
          2: "yiwancheng",
          3: "yiguoqi"
        };
        return obj[status];
      };
    }
  },
  created() {
    this.getEquipmentInfo();
    this.getData(this.activeName);
  },
  methods: {
    onAddFault() {
      this.$router.push({
        name: "equipmentFaultAdd",
        params: {
          id: this.info.id,
          info: this.info
        }
      });
    },
    onModuleClick(item) {
      this.$router.push({
        name: item.routerName,
        params: {
          id: this.id
        }
      });
    },
    // 修改日期
    onCalenderChange(v) {
      this.selectDate = v;
      this.tabDataObj = {
        a: [],
        b: [],
        c: [],
        d: [],
        f: []
      };
      this.page = 1;
      this.total = 0;
      this.getData(this.activeName);
    },
    // tab切换
    onTabChange(name, title) {
      this.$refs.calendar.getEquipmentHasData(this.activeName);
      this.tabDataObj = {
        a: [],
        b: [],
        c: [],
        d: [],
        f: []
      };
      this.loading = true;
      this.finished = false;
      this.page = 1;
      // 获取数据
      this.getData(name);
    },
    getData(tabName) {
      const obj = {
        a: "getEquipmentOverhaulRecord",
        b: "getEquipmentMaintainverhaulRecord",
        c: "getEquipmentPatrolRecord",
        d: "getEquipmentVerifyRecord",
        f: "getPointCheckRecord"
      };
      this[obj[tabName]]();
    },
    // 设备详情
    getEquipmentInfo() {
      getEquipmentInfo({ id: this.id, changeFlage: "cd" }).then(res => {
        this.info = res;
        // 如果是特种设备
        if (res.categoryName && res.categoryName.includes("特种设备")) {
          this.modules[this.modules.length - 1].hide = false;
        }
      });
    },
    // 检修记录
    async getEquipmentOverhaulRecord() {
      try {
        await getEquipmentOverhaulRecord(this.id, {
          planDateStart: this.selectDate.length ? this.selectDate[0] : "",
          planDateEnd: this.selectDate.length
            ? this.selectDate[1] || this.selectDate[0]
            : "",
          page: this.page++
        }).then(res => {
          this.loading = false;
          if (res.list && res.list.length) {
            this.tabDataObj.a = this.tabDataObj.a.concat(res.list);
            this.total = res.total;
          } else {
            this.finished = true;
          }
        });
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },
    // 润滑保养记录
    async getEquipmentMaintainverhaulRecord() {
      try {
        await getEquipmentMaintainverhaulRecord(this.id, {
          startDate: this.selectDate.length ? this.selectDate[0] : "",
          endDate: this.selectDate.length
            ? this.selectDate[1] || this.selectDate[0]
            : "",
          page: this.page++
        }).then(res => {
          this.loading = false;
          if (res.list && res.list.length) {
            this.tabDataObj.b = this.tabDataObj.b.concat(res.list);
            this.total = res.total;
          } else {
            this.finished = true;
          }
        });
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },
    // 巡检记录
    async getEquipmentPatrolRecord() {
      try {
        await getEquipmentPatrolRecord(this.id, {
          typeFlag: 1,
          terminalFlag: 1, // 终端标识： 0-pc， 1-app
          pointCheckFlag: 2, // 记录状态标识 点检任务标识为 1 (查询状态为0,1) 点检记录为2 (查询状态为2,3)
          startTime: this.selectDate.length
            ? this.selectDate[0] + " 00:00:00"
            : "",
          endTime: this.selectDate.length
            ? (this.selectDate[1] || this.selectDate[0]) + " 23:59:59"
            : "",
          page: this.page++
        }).then(res => {
          this.loading = false;
          if (res.list && res.list.length) {
            this.tabDataObj.c = this.tabDataObj.c.concat(res.list);
            this.total = res.total;
          } else {
            this.finished = true;
          }
        });
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },
    // 检定记录
    async getEquipmentVerifyRecord() {
      try {
        await getEquipmentVerifyRecord(this.id, {
          planDateStart: this.selectDate.length ? this.selectDate[0] : "",
          planDateEnd: this.selectDate.length
            ? this.selectDate[1] || this.selectDate[0]
            : "",
          page: this.page++
        }).then(res => {
          this.loading = false;
          if (res.list && res.list.length) {
            this.tabDataObj.d = this.tabDataObj.d.concat(res.list);
            this.total = res.total;
          } else {
            this.finished = true;
          }
        });
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },
    // 点检记录
    async getPointCheckRecord() {
      const params = {
        equipmentId: this.id,
        actualStartTime: this.selectDate.length
          ? this.selectDate[0] + " 00:00:00"
          : "",
        actualEndTime: this.selectDate.length
          ? (this.selectDate[1] || this.selectDate[0]) + " 23:59:59"
          : "",
        typeFlag: 2,
        pointCheckFlag: 2,
        terminalFlag: 1,
        page: this.page++
      };
      try {
        await getPointCheckRecord(params).then(res => {
          this.loading = false;
          if (res.list && res.list.length) {
            this.tabDataObj.f = this.tabDataObj.f.concat(res.list);
            this.total = res.total;
          } else {
            this.finished = true;
          }
        });
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },
    // 跳转到基本信息
    onToInfo() {
      this.$router.push({
        name: "equipmentledgerInfo",
        params: { id: this.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-wrapper {
  height: 100%;
  display: flex;
  background-color: #eceef2;
  flex-direction: column;
  .equipment-info {
    position: relative;
    flex: 1;
    overflow-y: auto;
    .report-fault {
      position: fixed;
      width: 52px;
      height: 52px;
      text-align: center;
      line-height: 52px;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgb(26, 99, 251);
      color: #fff;
      font-weight: 700;
      border-radius: 50%;
      font-size: 14px;
      z-index: 90;
      box-shadow: 0 5px 20px 0px #ccc;
    }
    // 设备基本信息
    .equipment-desc {
      padding: 12px;
      background-color: #fff;
      &-box {
        height: 128px;
        border-radius: 12px;
        background: url("../../assets/images/equipment/bg-normal.svg") no-repeat
          center center;
        background-size: cover;
        position: relative;
        padding: 20px 25px;
        box-sizing: border-box;
        position: relative;
        .equipment-detail-btn {
          position: absolute;
          right: 12px;
          bottom: 16px;
          height: 28px;
          line-height: 28px;
          width: 97px;
          border-radius: 14px;
          background-color: #056cff;
          color: #fff;
          font-size: 12px;
          text-align: center;
          .iconfont {
            font-size: 12px;
            margin-left: 6px;
          }
        }
        .equipment-type {
          position: absolute;
          top: 21px;
          right: 0;
          height: 21px;
          line-height: 21px;
          border-radius: 11px 0 0 11px;
          padding: 0 12px;
          background-color: #1676ff;
          font-size: 12px;
          color: #cfe2fd;
          font-weight: 700;
        }
        .equipment-status {
          font-size: 12px;
          color: #ffffff;
          margin-top: 20px;
          .mark {
            width: 8px;
            height: 8px;
            opacity: 1;
            border: 2px solid #ffffff;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
          }
          .mark1 {
            background: #24e351;
          }
          .mark2 {
            background: #aeb3c0;
          }
          .mark3 {
            background: #1676ff;
          }
          .mark4 {
            background: #f75858;
          }
          .mark5 {
            background: #ffa860;
          }
        }
        .equipment-name {
          color: #fff;
          font-weight: 700;
          font-size: 18px;
          .equipment-no {
            font-size: 14px;
          }
        }
        .equipment-category {
          margin-top: 10px;
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          color: #4e4e4e;
          background: url("../../assets/images/equipment/type-normal.svg")
            no-repeat left center;
          padding-left: 20px;
        }
        .eqipment-type2 {
          margin-top: 26px;
          font-size: 12px;
          color: rgb(203, 223, 254);
        }
        .scrap-mark {
          position: absolute;
          top: 50px;
          left: 140px;
          transform: rotate(-28deg);
          font-size: 44px;
          opacity: 0.46;
          color: #fff;
        }
        &.scrap {
          background-image: url("../../assets/images/equipment/bg-scrap.svg");
          background-size: cover;
          .equipment-name {
            color: #888784;
          }
          .equipment-category {
            color: #4e4e4e;
            background: url("../../assets/images/equipment/type-scrap.svg")
              no-repeat left center;
          }
          .equipment-detail-btn {
            background-color: #cecdcc;
            color: #888784;
          }
          .equipment-type {
            background-color: rgba(255, 255, 255, 0.3);
            color: #888784;
          }
          .eqipment-type2 {
            color: #888784;
          }
        }
        &.key {
          background-image: url("../../assets/images/equipment/bg-key.svg");
          background-size: cover;
          .equipment-category {
            background: url("../../assets/images/equipment/type-key.svg")
              no-repeat left center;
          }
          .equipment-detail-btn {
            background-color: #afb1bf;
            color: #fff;
            box-shadow: 0px 1px 14px 0px #d8d8d8;
          }
          .equipment-type {
            background-color: #d8d9df;
            color: #292627;
          }
          .equipment-name {
            color: #32312e;
            .equipment-no {
            }
          }
          .eqipment-type2 {
            color: rgb(70, 70, 70);
          }
        }
        &.core {
          background: url("../../assets/images/equipment/bg-core.svg") no-repeat
            center center;
          background-size: cover;
          .equipment-category {
            background: url("../../assets/images/equipment/type-core.svg")
              no-repeat left center;
          }
          .equipment-detail-btn {
            background-color: #292627;
            color: #fdd7b5;
            box-shadow: 0px 2px 16px 0px rgba(229, 189, 152, 0.46),
              0px 2px 4px 0px rgba(112, 78, 49, 0.56);
          }
          .equipment-type {
            background-color: rgba(255, 255, 255, 0.3);
            color: #292627;
          }
          .equipment-name {
            color: #32312e;
            .equipment-no {
            }
          }
          .eqipment-type2 {
            color: #32312e;
          }
        }
      }
    }
    // 设备模块
    .equipment-modules {
      margin-bottom: 10px;
      background-color: #fff;
      .equipment-module {
        height: 55px;
        width: 25%;
        margin-bottom: 12px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .equipment-module-icon {
          flex: 1;
          display: flex;
          align-items: center;
          .iconfont {
            display: inline-block;
            height: 24px;
            height: 24px;
            font-size: 24px;
          }
          .icon {
            width: 24px;
            height: 24px;
            font-size: 30px;
            &.svg-icon {
              fill: currentColor;
            }
          }
        }
        .equipment-module-name {
          font-size: 14px;
          color: #222;
          margin-top: 9px;
          font-weight: 500;
        }
      }
    }
    // 日历
    .equipment-calender {
      background-color: #fff;
      padding: 16px 16px 0 16px;
    }
    .equipment-tabs {
      background-color: #fff;
      ::v-deep {
        .van-tab__pane {
          min-height: 400px;
          padding-top: 14px;
        }
      }
      .total {
        font-size: 12px;
        color: #a0a2a6;
        text-align: center;
        margin-bottom: 10px;
      }
      .iconfont-daizhixing {
        color: #dea148;
      }
      .iconfont-zhihangzhong {
        color: #3683f4;
      }
      .iconfont-yiwancheng {
        color: #8eb433;
      }
      .iconfont-yiguoqi {
        color: #5c7ba2;
      }
      .iconfont {
        font-weight: normal;
      }
      .equipment-item {
        position: relative;
        padding: 0 20px 0;
        .item-project {
          font-size: 18px;
          color: #141313;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
          .left {
            max-width: 320px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .name {
            max-width: 150px;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .type {
            color: #1676ff;
            font-size: 10px;
            display: inline-block;
            padding: 3px 5px;
            font-weight: normal;
            background: rgba(22, 118, 255, 0.1);
            border-radius: 2px 2px 2px 2px;
            margin-left: 5px;
          }
          .scheme-type {
            color: #ffffff;
            font-size: 10px;
            display: inline-block;
            padding: 3px 5px;
            font-weight: normal;
            background: #f49523;
            border-radius: 2px 2px 2px 2px;
            margin-left: 5px;
            max-width: 80px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .info {
            color: #a0a2a6;
            font-size: 12px;
            font-weight: normal;
            margin-top: 10px;
            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              margin-right: 15px;
            }
            .iconfont-xunjian {
              margin-right: 5px;
            }
          }
        }
        .item-mode {
          position: absolute;
          right: 16px;
          top: 0;
          font-size: 12px;
          padding: 4px 8px;
          background-color: rgba(254, 119, 11, 0.1);
          border-radius: 14px;
          color: #fe770b;
        }
        .check-item {
          margin-bottom: 4px;
          .check-item-object {
            margin-top: 8px;
            font-weight: 600;
            overflow: hidden;
            > div {
              float: left;
              width: 33.3%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .check-item-header {
            margin-top: 16px;
            font-size: 14px;
            overflow: hidden;
            > div {
              float: left;
              width: 33.3%;
            }
          }
          .c-green {
            color: #57c443;
          }
          .c-red {
            color: #f56c6c;
          }
          .tc {
            text-align: center;
          }
          .tr {
            text-align: right;
          }
        }
      }
      .check {
        .title-box {
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }
        }
        .check-num-box {
          .item {
            display: inline-block;
            margin: 0 40px 0 0;
            .label {
              font-size: 12px;
              font-weight: 400;
              color: #aaaaaa;
              line-height: 17px;
            }
            .val {
              font-size: 14px;
              font-weight: 700;
              color: #333333;
              line-height: 16px;
              margin: 0 0 2px;
            }
            .val-res {
              font-size: 14px;
              font-weight: 500;
              color: #3f9f11;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
